@font-face {
  font-family: 'Hello Sunday';
  src: url('../public/Hello-Sunday.otf') format('opentype');
}

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #6b7eb9;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 100%;
  margin: 0;
}

button {
  background: #e29daa;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-family: inherit;
  font-size: 20px;
  padding: 5px 10px;

  > img,
  > svg {
    display: block;
  }

  &:has(img) {
    padding: 5px;
  }

  &:disabled {
    opacity: 0.6;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background: darken(#e29daa, 5%);
    }

    &:active {
      background: darken(#e29daa, 10%);
    }
  }
}

input {
  border: 1px solid #000;
  border-radius: 25px;
  font-size: 16px; // minimum to prevent zooming when autofocusing on iOS
  padding: 5px 10px;
  width: 100%;
}

#root {
  height: 100%;
}
