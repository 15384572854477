$innerWidth: 520px;

.App {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  > .body {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    width: calc(100% - 60px);

    > button {
      font-weight: bold;
      margin: 10px 0 0;

      &.bottomMargin {
        margin-bottom: 10px;
      }

      &.reset {
        background: transparent;
        font-size: 15px;
        margin: 0;
        padding: 0;
      }
    }

    > img {
      background: #000;
      height: 100%;
      left: 0;
      object-fit: contain;
      position: absolute;
      top: 0;
      width: 100%;

      &.hide {
        display: none;
      }
    }

    > .heading {
      font-family: 'Hello Sunday';
      font-size: 50px;
      padding: 20px 0;
      text-align: center;
    }

    > .subHeading,
    > div > div > .subHeading {
      font-family: 'Hello Sunday';
      font-size: 35px;
      white-space: nowrap;
    }

    > .people {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: $innerWidth;
      width: 100%;

      &:not(:empty) {
        margin-bottom: 20px;
      }

      > div {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: center;
        width: 100%;

        > button {
          background: transparent;
          display: flex;
          flex: 1;
          justify-content: center;
          padding: 0;
        }

        > input {
          flex: 8;
        }
      }
    }

    > .beds {
      max-width: $innerWidth;
      width: 100%;

      > .header {
        display: flex;
        font-weight: bold;
        gap: 10px;
        padding-bottom: 5px;

        > div:not(.spacer) {
          flex: 2;
          white-space: nowrap;

          &.wide {
            flex: 6;
          }
        }

        > .spacer {
          flex: 1;
        }
      }

      > .bed {
        align-items: center;
        display: flex;
        gap: 10px;

        > .emoji {
          flex: 1;
          font-size: 28px;
          text-align: center;
        }

        > input {
          height: fit-content;

          &:nth-of-type(1) {
            flex: 6;
          }

          &:nth-of-type(2) {
            flex: 2;
          }
        }

        > button {
          background: transparent;
          display: flex;
          flex: 1;
          justify-content: center;
          padding: 0;
        }
      }
    }

    > .error {
      text-align: center;

      &:not(:empty) {
        padding: 20px 0 10px;
      }
    }

    label {
      align-items: center;
      display: flex;
      gap: 5px;
      margin-top: 20px;

      > input {
        width: unset;
      }

      > span {
        flex-shrink: 0;
      }
    }

    > .results {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      justify-content: center;
      margin-top: 10px;
      max-width: 1000px;
      width: 100%;

      > div {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 5px;

        > .people {
          display: flex;
          flex-direction: column;
          gap: 5px;

          > div {
            align-items: center;
            display: flex;
            gap: 10px;

            > .avatar {
              display: flex;

              > svg {
                height: 25px;
                width: 25px;
              }
            }

            > .name {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  > .footer {
    padding: 20px 0;

    > a {
      color: #fff;
    }
  }
}
